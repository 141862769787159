@mixin large-screen {
    @media (max-width: 996px) {
        @content;
    }
}

@mixin medium-screen {
    @media (max-width: 826px) {
        @content;
    }
}

@mixin small-screen {
    @media (max-width: 576px) {
        @content;
    }
}
